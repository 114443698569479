<div class="row d-flex justify-content-center" >
    <div class="col-md-12">
        <div class="card">
            <div class="card-body-form">
                <h4 routerLink="/" style="cursor: pointer" class="text-white text-center"><img src="/../assets/images/redlogo.svg" alt="" width="250px"></h4>
                <h2 class="w-100 text-center mb-3">Welcome Back!</h2>
                <p>Please enter you email address to continue</p>
                <form [formGroup]="forgotform" (ngSubmit)="onSubmit()">
                    <div class="form-group ">
                        <input type="email" formControlName="email" placeholder="Email Address">
                        <div class="error" *ngIf="submitted && f.email.errors">
                            <div *ngIf="f.email.errors.required">
                                <p>This is a required entry</p>
                            </div>
                            <div *ngIf="f.email.errors.pattern">
                                <p>Email filed only accepts valid domains</p>
                            </div>
                        </div>
                       
                        <!-- <p class="text-right text-white w-100" >forgot password?</p> -->
                    </div>
                </form>
                <p class="text-center"><button class="free-quotes text-black border-0 font-weight-bold w-100 login-btn" type="submit" (click)="onSubmit()">Forgot Password</button></p>
            </div>
            <div class="card-botom">
                <p class="text-white cursor-pointer text-center w-100" (click)="cross()">close</p>
            </div>
        </div>
    </div>
</div>