export const Regex = {
    phoneNumbers: new RegExp('^[1-9][0-9]*$'),
    phoneNumber: new RegExp('^[0-9][0-9]*$'),
    email: new RegExp(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/),
    webUrl: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
    passwordStrong: new RegExp(/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/),
    passwordEasy: new RegExp(''),
    spaces: new RegExp(/^\S+$/),
    spacecharacter: new RegExp(/^[A-Za-z]+(?:[ -][A-Za-z]+)*$/),
    spacesData:new RegExp(/^[A-Za-z]+(?:[ -][A-Za-z]+)*$/),
    fTemprature:new RegExp(/[0-9-]/),
    cTemprature:new RegExp(/[0-9]/),
};