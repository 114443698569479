// import { Component } from '@angular/core';
import AOS from 'aos';

// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.scss']
// })
// export class AppComponent {
//   title = 'magical-india';

//   ngOnInit(): void {
//     AOS.init();
//   }
// }
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { TitleCasePipe } from '@angular/common';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  constructor( 
              private route: Router,
              private titleService: Title,
              // private titlecasePipe:TitleCasePipe ,
              private meta: Meta) {
                route.events.subscribe((val) => {
                  this.pageReloadHeading();
                 });
              }
  ngOnInit(): void {
        AOS.init();
    this.pageReloadHeading();
  }
  pageTitle:any

  pageReloadHeading(){
    var spiltedrUrl = this.route.url.split("/");
    this.pageTitle = spiltedrUrl[1];
    if(this.pageTitle == ''){
      this.titleService.setTitle('Magical India Journey- Book Best tour packages for Varanasi and nearby');
      document.getElementById('home').classList.add('active-link');

      this.meta.addTag({name:'vijay', content: 'Book Varanasi tour packages with all inclusive feature at very initial price. Multi-language guide with trained driver and sanitized cabs.' })
      this.meta.updateTag({name:'keywords', content:'Varanasi tour packages, devdewali tour packages in Varanasi, Sarath tour packages, Varanasi day tour'})
    }
    else{
      this.pageTitle = this.pageTitle.replace('-',' ').split(' ')
      .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
      .join(' ');
      document.getElementById('home').classList.remove('active-link');

      this.titleService.setTitle((this.pageTitle));
      this.meta.addTag({name:'vijay', content: ' Varanasi tour packages, devdewali tour packages in Varanasi, Sarath tour packages, Varanasi day tour' })

    }
  }
  // ngOnit(){
  //   this.titleService.setTitle(this.title)
  // }
}
