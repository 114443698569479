import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { BannerBlueComponent } from './shared/banner-blue/banner-blue.component';
import { BannerCardComponent } from './shared/banner-card/banner-card.component';

const routes: Routes = [


  {
    path: '',
    component: AppComponent,
    children: [
      {
        path:'auth',
        loadChildren: () => import('../app/auth/auth.module').then(m => m.AuthModule)
      },
      {
        path:'',
        loadChildren: () => import('./shared/shared.module').then(m => m.SharedModule)
      }

    
    ],
    
  },
 
  

  


  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false, scrollPositionRestoration: 'enabled'} )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
