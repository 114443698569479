<div class="container py-5">
  <div class="row align-items-center">
    <div class="col-md-6">
      <h2 class="text-black text-uppercase text-center font-weight-bold" data-aos="fade-up" data-aos-duration="1600">Recognition & Approved</h2>
      <p class="text-black text-center " data-aos="fade-up" data-aos-duration="1600"> We are a recognized platform by the Ministry of Tourism, the Government of India, and other organizations like ADTOI. We have all Government approved tourist guides.</p>

      <!-- <img class="g-trans my-2 bg-white"
      src="/../assets/images/badge.png" width="150px" height="300px"
      alt=""> -->
      <img class="g-trans my-2 bg-white"
      src="/../assets/images/new_img/mij-approval-logo.png" width="150px" height="300px"
      alt="">
      <!-- <img class="g-trans my-2 bg-white"
      src="/../assets/images/new_img/india-incrediable.png" width="150px" height="125px"
      alt=""> -->
    </div>
    
    <div class="col-md-6">

    <div class="d-flex justify-content-around flex-wrap">
      <div class="col-md-6">
        <div class="d-flex justify-content-center py-3">
            <img src="../../../assets/images/XMLID_170_.svg" width="105px" height="105px" alt="">
        </div>
        <div class="text-center">
            <h4 data-aos="fade-up"  data-aos-duration="1600">40+ Guides</h4>
        </div>
        <div class="text-center">
            <p data-aos="fade-up"  data-aos-duration="1600">We have Government approved tourist guide with valid Id' card.</p>
        </div>
    </div>
    <div class="col-md-6">
        <div class="d-flex justify-content-center py-3">
            <img src="../../../assets/images/place.svg" alt=""  width="105px" height="105px" >
        </div>
        <div class="text-center">
            <h4 data-aos="fade-up"  data-aos-duration="1600">80+ Sightseen </h4>
        </div>
        <div class="text-center">
            <p data-aos="fade-up"  data-aos-duration="1600">We have around 80+ Sightseeing available</p>
        </div>
    </div>
    <div class="col-md-6">
        <div class="d-flex justify-content-center py-3">
            <img src="../../../assets/images/hot1.jpg" alt="" class="rounded-circle vehiclescar">
        </div>
        <div class="text-center">
            <h4 data-aos="fade-up"  data-aos-duration="1600">10+ Hotels</h4>
        </div>
        <div class="text-center">
            <p data-aos="fade-up"  data-aos-duration="1600">We have contracts of more than 10 hotels in Varanasi and nearby places.</p>
        </div>
    </div>
    <div class="col-md-6">
      <div class="d-flex justify-content-center py-3">
          <img src="../../../assets/images/car.jpg" alt="" class="rounded-circle vehiclescar"  >
      </div>
      <div class="text-center">
          <h4 data-aos="fade-up"  data-aos-duration="1600">10+ Vehicles</h4>
      </div>
      <div class="text-center">
          <p data-aos="fade-up"  data-aos-duration="1600">We have a fleet of all types of vehicles with trained and professional drivers.</p>
      </div>

    </div>
  </div>
    </div>
</div>




<!-- <section class="container mt-5 mb-5">
    <div class="row">
        <div class="col-md-6">
            <div class="d-flex justify-content-center">
                <img src="../../../assets/images/XMLID_170_.svg" alt="">
            </div>
            <div class="text-center">
                <h4 data-aos="fade-up"  data-aos-duration="1600">1,000+ Guides</h4>
            </div>
            <div class="text-center">
                <p data-aos="fade-up"  data-aos-duration="1600">Lorem ipsum dolor sit amet consecteting elit. Neque id fugit laudantium</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="d-flex justify-content-center">
                <img src="../../../assets/images/place.svg" alt="">
            </div>
            <div class="text-center">
                <h4 data-aos="fade-up"  data-aos-duration="1600">1,000+ Guides</h4>
            </div>
            <div class="text-center">
                <p data-aos="fade-up"  data-aos-duration="1600">Lorem ipsum dolor sit amet consectetur adipisicing elugit laudantium</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="d-flex justify-content-center">
                <img src="../../../assets/images/XMLID_170_.svg" alt="">
            </div>
            <div class="text-center">
                <h4 data-aos="fade-up"  data-aos-duration="1600">1,000+ Guides</h4>
            </div>
            <div class="text-center">
                <p data-aos="fade-up"  data-aos-duration="1600">Lorem ipsum dolor sit amet consectetur adipiNeque id fugit laudantium</p>
            </div>
        </div>
    </div>
</section> -->
