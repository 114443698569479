<div class="container-fluid">
    <div class="container">
        <p class="text-center pt-5 font-weight-medium">
            Pick our most browsed packages and explore the ancient city of the world with our expert guide and super accommodations. we are providing end-to-end management of the Varanasi tour. We are providing tour assistance for the last 10 years and we have clients across the globe. We have a bunch of features to make your travel and sightseen comfortable and hassle-free. These all packages are designed by our experts who are serving in this industry for the last 20+ years. This unique researched package will give you a real exploring experience in Varanasi. Just click away to know more about the packages and feature you will get. Please drop your details, we will guide you more in your explorations and customized packages.
        </p>
        <div class="w-100 py-4 d-flex justify-content-center">
            <p class="text-center s-para px-2 text-black"> <img src="/../assets/images/tel.png" width="45px" height="45px" alt=""> For best tour packages, call us at <span class="font-weight-bold cursor-pointer">+91 945-539-3696</span></p>
        </div>
        <div class="row py-4">
            <div class="col-md-3  text-center" style="height: fit-content;" *ngFor="let item of tours">
                <div class="tour-card my-3" data-aos="fade-up"  data-aos-duration="1600"  [ngStyle]="{'background-image': 'url(' + item.cat_img_URL + ')'}" >
                    <div class="tour-overlay  mx-0 p-2">
                        <div class="spacer" style="height: 150px;"></div>
                        <p class="text-center w-100"><button class="g-trans font-weight-bold text-white bg-transparent border-0 px-4 py-1" (click)="changeRoute(item)" >{{item.cat_name}}</button></p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <app-packcat-card></app-packcat-card> -->
        <!-- <div class="row py-4">
                    <div class="col-md-4  text-center" style="height: fit-content;" *ngFor="let item of items">
                        <div class="card d-flex">
                            <div class="w-40 card-img">
                                <img [src]="item.img" width="150px" class="h-100" style="position: relative;">
                                <img [src]="item.badge" style="width: 45px; position: absolute; left: 0px;">
                            </div>
                            <div class="w-60 card-body text-left">
                                <h3>{{item.head}}</h3>
                                <div class="star">
                                    <img [src]="item.star" width="20px">
                                    <img [src]="item.star" width="20px">
                                    <img [src]="item.star" width="20px">
                                    <img [src]="item.star" width="20px">
                                    <img [src]="item.offstar" width="20px">
                                </div>
                                <div class="justify-content-between">
                                    <p>{{item.des}} </p>
                                </div>
                                <button class="card-btn g-trans my-2 font-weight-bold border-0 text-white" routerLink='/tour-detail'>{{item.btn}}</button>
                            </div>
                        </div>
                    </div>
               
            <div class="col-md-4">
                <div class="log-card text-center">
                    <img src="/../assets/images/Mask Group 142@2x.png" width="250px" >
                    <h2 class="" data-aos="fade-up"  data-aos-duration="1600">View All Varanasi Packages</h2>
                    <button class="long-btn g-trans bg-transparent mx-0 my-3 text-black font-weight-bold" data-aos="fade-up"  data-aos-duration="1600" routerLink="/tour-detail">View</button>
                    <div class="spacer" style="height: 375px;"></div>
                </div>
            </div>
        </div> -->

      
    </div>
</div>