<section class="above-header">
    <div class="container">
        <div class="d-flex justify-content-between text-black">
            <div>
                <img class="mb-1 px-1" src="/assets/images/whatsapp.svg" alt="whatsappicon">
                <a class="text-decoration-none" href="tel:9455393696"><span class="px-1">+91</span>
                  <span>945-539-3696</span></a>

                <img class="mb-1 px-1 ml-5" style="height: 20px;" src="../../../assets/images/call-landline.svg">
                <a class="text-decoration-none" href="tel:05422282141"><span class="px-1">054</span>
                  <span >222-82-141</span></a>

                <img class="mb-1 px-1 ml-5" src="/assets/images/gmail.svg" alt="gmailicon">
                <a class="text-decoration-none" href="mailto:info@magicalindiajourney.com"> <span class="px-1">info@magicalindiajourney.com</span></a>
                <!-- <span>831-820-2800</span> -->
            </div>
            <div>
                <a href="https://www.facebook.com/magicalindiaOff" target="_blank"><img class="mr-2" src="../../../assets/images/fb_c.svg" alt="facebook"></a>
                <a target="_blank" href="https://www.linkedin.com/company/magical-india/"><img class="mr-2" src="../../../assets/images/in_c.svg" alt="linkedin"></a>
                <a target="_blank" href="https://www.instagram.com/mij_vns/"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 48 48">
                  <radialGradient id="yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1" cx="19.38" cy="42.035" r="44.899" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fd5"></stop><stop offset=".328" stop-color="#ff543f"></stop><stop offset=".348" stop-color="#fc5245"></stop><stop offset=".504" stop-color="#e64771"></stop><stop offset=".643" stop-color="#d53e91"></stop><stop offset=".761" stop-color="#cc39a4"></stop><stop offset=".841" stop-color="#c837ab"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><radialGradient id="yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2" cx="11.786" cy="5.54" r="29.813" gradientTransform="matrix(1 0 0 .6663 0 1.849)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#4168c9"></stop><stop offset=".999" stop-color="#4168c9" stop-opacity="0"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><path fill="#fff" d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"></path><circle cx="31.5" cy="16.5" r="1.5" fill="#fff"></circle><path fill="#fff" d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"></path>
                  </svg></a>
                <!-- <img class="" src="../../../assets/images/tw_c.svg" alt="gmailicon"> -->
                <select  class="mx-2 p-1 bg-light form-select" aria-label="Default select example">
                  <option selected>Language</option>
                  <option value="1">EN</option>
                  <option value="2" disabled>RUS</option>
                  <option value="3" disabled>ES</option>
                </select>
            </div>
        </div>
    </div>
</section>
<div class="container-fluid" id="header">
  <div class="container">
    <section class="header">
      <!--Navbar-->
    <mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark" [containerInside]="false">
      <!-- Navbar brand -->
      <mdb-navbar-brand><h3 class="navbar-brand cursor-pointer" routerLink='/' ><img src={{logo}} width="270px" height="65px" alt=""></h3>  </mdb-navbar-brand>
      <!-- Collapsible content -->
      <links>
        <!-- Links -->
        <ul class="navbar-nav ml-auto">
          <!-- <li class="nav-item" *ngFor="let item of list">
            <h3 class="nav-link font-weight-bold waves-light cursor-pointer" mdbWavesEffect routerLink='{{item.link}}' routerLinkActive="active-link">{{item.menu}} </h3>
          </li>  -->
          <li class="nav-item">
            <h3 class="nav-link font-weight-bold waves-light cursor-pointer" mdbWavesEffect routerLink='/' id="home">Home </h3>
          </li> 

          <li class="dropdown" mdbDropdown>

            <h3 mdbDropdownToggle  class="nav-link font-weight-bold waves-light cursor-pointer"
              mdbWavesEffect routerLink='/about-us' routerLinkActive="active-link">
              About Us
          </h3>
            <!-- <div class="dropdown-menu dropdown-primary">
              <a class="dropdown-item" href="#">Our Recognition.</a>
              <a class="dropdown-item" href="#">Meet us</a>
            </div> -->
          </li>

          <li class="dropdown" mdbDropdown>

            <h3 mdbDropdownToggle  class="nav-link font-weight-bold waves-light cursor-pointer"
              mdbWavesEffect routerLink='/packages' routerLinkActive="active-link">
              Packages
          </h3>
<!--           
            <div class="dropdown-menu dropdown-primary">
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <a class="dropdown-item" href="#">Something else here</a>
            </div> -->
          </li>
          <li class="nav-item">
            <h3 class="nav-link font-weight-bold waves-light cursor-pointer" mdbWavesEffect routerLink='/contact-us' routerLinkActive="active-link">Contact Us </h3>
          </li> 
          <li class="nav-item">
            <button class="nav-link font-weight-bold waves-light cursor-pointer view-all text-white border-0 mb-0 px-4 py-2" mdbWavesEffect  (click)="GetTouch()">Get in Touch </button>
          </li> 
        </ul>
      </links>
      <!-- Collapsible content -->
    </mdb-navbar>
    <!--/.Navbar-->
    </section>
  </div>

</div>
