<div>
    <app-header></app-header>
</div>
<!-- <div>
    <app-banner-blue></app-banner-blue>
</div> -->
<div>
    <router-outlet></router-outlet>
 </div>   
<div>
    <app-footer></app-footer>
</div>
