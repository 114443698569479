// import { Component, OnInit } from '@angular/core';
// import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// @Component({
//   selector: 'app-booking',
//   templateUrl: './booking.component.html',
//   styleUrls: ['./booking.component.scss']
// })
// export class BookingComponent implements OnInit {

 
//   constructor(private modalService: NgbModal
//     ,private fb: FormBuilder,
//     ) { }
//   submitted = false;

// 	step1 = true;
// 	step2 = false;
// 	step3 = false;
// 	bookingUpFormStep1: FormGroup;
// 	bookingUpFormStep2: FormGroup;
// 	bookingUpFormStep3: FormGroup;
// 	numberinvalid: boolean;
// 	submitted1 = false;
// 	submitted2 = false;
// 	submitted3 = false;
//   // public firstName: string = '';
// 	// public password: string = '';
// 	// public message: string = '';

//   ngOnInit(): void {
// 		this.bookingUpStep1();
// 		this.bookingUpStep2();
// 		this.bookingUpStep3();
//   }
//   showStep2() {
// 		this.step1 = false;
// 		this.step2 = true;
// 	}
// 	showStep3() {
// 		this.step2 = false;
// 		this.step3 = true;
// 	}
  
// 	bookingUpStep1() {
// 		let emailregex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z0-9]{2,}))$/);
//     	this.bookingUpFormStep1 = new FormGroup ({
//       fullName: new FormControl(null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(20)])),
//       email: new FormControl (null, Validators.compose([Validators.required, Validators.pattern(emailregex)])),
//       description: new FormControl (null,Validators.compose( [Validators.required, Validators.minLength(25)]))

// 		});
// 	}

// 	bookingUpStep2() {
//     this.bookingUpFormStep2 = new FormGroup ({
//       cardName: new FormControl(null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(20)])),
//       pin: new FormControl (null, Validators.compose([Validators.required])),
//       cvv: new FormControl (null,Validators.compose( [Validators.required, Validators.maxLength(3), Validators.minLength(3)]))

// 		});
// 	}

// 	bookingUpStep3() {
// 		let emailregex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z0-9]{2,}))$/);
//     	this.bookingUpFormStep3 = this.fb.group({
//         message: new FormControl(null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(20)])),
// 		});
// 	}

//   onSubmitStep1(post) {
// 		// console.log(post, 'postData');
// 		this.submitted1 = true;
// 		if (this.bookingUpFormStep1.valid) {
// 			// this.firstName = post.firstName;
// 			// this.email = post.email.toLowerCase();
// 			// this.phoneNumber = post.phoneNumber;
// 			this.showStep2();
// 		}
// 	}

//   get f() { return this.bookingUpFormStep1.controls; }
//   get s() { return this.bookingUpFormStep2.controls; }
//   get t() { return this.bookingUpFormStep3.controls; }

// 	onSubmitStep2(post) {
// 		this.submitted2 = true;
// 		if (this.bookingUpFormStep2.valid) {
// 			// this.venueName = post.venueName;
// 			// this.streetAddress = post.streetAddress ? post.streetAddress : this.streetAddress;
// 			this.showStep3();
// 		}
// 		else {
// 			// this.snackbar.open('Please  complete the required details', 'Warning', {
// 				// duration: 30000
// 			// });
// 		}
// 	}

// 	onSubmitStep3(post) {
// 		this.submitted3 = true;
// 		if (this.bookingUpFormStep3.valid) {
// 			let formData = {
// 				// firstName: this.firstName,
// 				// lastName: this.lastName,
// 				// profileImage: this.venue_asset_url + 'assets/Images/profile-banner.jpg'
// 			}
// 		}
// 	}


//   cross(){
//     this.modalService.dismissAll();
//     window.location.reload();

//   }
//   // get formControls(){
//   //   return this.loginForm.controls
//   // }


//   // onSubmit(){
//   //   if(this.loginForm.invalid){
//   //    this.show = true;
//   //    alert('valid')
//   //   }
//   // }
// }










import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {

	model: NgbDateStruct;

  constructor(private modalService: NgbModal

    ,private fb: FormBuilder,
    ) { }
  submitted = false;

	step1 = true;
	step2 = false;
	step3 = false;
	formhead:string='Book Now'
	bookingUpFormStep1: FormGroup;
	bookingUpFormStep2: FormGroup;
	bookingUpFormStep3: FormGroup;
	numberinvalid: boolean;
	submitted1 = false;
	submitted2 = false;
	submitted3 = false;
  // public firstName: string = '';
	// public password: string = '';
	// public message: string = '';

  ngOnInit(): void {
		this.bookingUpStep1();
		this.bookingUpStep2();
		this.bookingUpStep3();
  }
  showStep2() {
		this.step1 = false;
		this.step2 = true;
		this.formhead="Details"
	}
	showStep3() {
		this.step2 = false;
		this.step3 = true;
		this.formhead=""
	}
  
	bookingUpStep1() {
		let emailregex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z0-9]{2,}))$/);
		let phoneNumber = new RegExp('^[0-9][0-9]*$');
    	this.bookingUpFormStep1 = new FormGroup ({
      fullName: new FormControl(null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(20)])),
      email: new FormControl (null, Validators.compose([Validators.required, Validators.pattern(emailregex)])),
      contactNo: new FormControl (null,Validators.compose( [Validators.required, Validators.pattern(phoneNumber)])),
	  country: new FormControl (null,Validators.compose( [Validators.required]))
		});
	}

	bookingUpStep2() {
    this.bookingUpFormStep2 = new FormGroup ({
    //   cardName: new FormControl(null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(20)])),
    //   pin: new FormControl (null, Validators.compose([Validators.required])),
    //   cvv: new FormControl (null,Validators.compose( [Validators.required, Validators.maxLength(3), Validators.minLength(3)])),
	numAdult: new FormControl(null,Validators.required),
	numChild: new FormControl(null,Validators.required),
	hotelCat: new FormControl(null,Validators.required),
	information: new FormControl(null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(20)])),
	dateArrival: new FormControl(null,Validators.required)
		});
	}

	bookingUpStep3() {
		let emailregex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z0-9]{2,}))$/);
    	this.bookingUpFormStep3 = this.fb.group({
        message: new FormControl(null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(20)])),
		});
	}

  onSubmitStep1(post) {
		// console.log(post, 'postData');
		this.submitted1 = true;
		if (this.bookingUpFormStep1.valid) {
			// this.firstName = post.firstName;
			// this.email = post.email.toLowerCase();
			// this.phoneNumber = post.phoneNumber;
			console.log('enter')
			this.showStep2();
		}
		else{
			console.log('exit')

		}
	}

  get f() { return this.bookingUpFormStep1.controls; }
  get s() { return this.bookingUpFormStep2.controls; }
  get t() { return this.bookingUpFormStep3.controls; }

  close(){
    this.modalService.dismissAll();

  }
	onSubmitStep2(post) {
		this.submitted2 = true;
		if (this.bookingUpFormStep2.valid) {
			// this.venueName = post.venueName;
			// this.streetAddress = post.streetAddress ? post.streetAddress : this.streetAddress;
			this.showStep3();
		}
		else {
			// this.snackbar.open('Please  complete the required details', 'Warning', {
				// duration: 30000
			// });
		}
	}

	// onSubmitStep3(post) {
		// this.submitted3 = true;
		// if (this.bookingUpFormStep3.valid) {
			// let formData = {
				// firstName: this.firstName,
				// lastName: this.lastName,
				// profileImage: this.venue_asset_url + 'assets/Images/profile-banner.jpg'
			// }
		// }
	// }


  cross(){
    this.modalService.dismissAll();
    window.location.reload();

  }
  // get formControls(){
  //   return this.loginForm.controls
  // }


  // onSubmit(){
  //   if(this.loginForm.invalid){
  //    this.show = true;
  //    alert('valid')
  //   }
  // }
}
