import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BookingComponent } from 'src/app/all-popup/booking/booking.component';
import { PackageTypeJson } from 'src/app/shared/model';
import { PackageService } from 'src/app/shared/service/package.service';

@Component({
    selector: 'app-m-packet-tour',
    templateUrl: './m-packet-tour.component.html',
    styleUrls: ['./m-packet-tour.component.scss']
  })
export class MPacketTourComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    private packageService: PackageService,
    private router: Router
    ) { }

  tours: PackageTypeJson[];

  ngOnInit(): void {
    this.packageService.getPackageType().subscribe(response => {
      this.tours = response;
    })
  }

  changeRoute(packageType: PackageTypeJson) {
    this.router.navigate(['/tour', packageType.cat_name ]); 
  }
  onbook(){
    console.log('work')
    this.modalService.open( BookingComponent, { backdropClass: 'light-blue-backdrop', centered: true ,size: 'xl' });
  }

}
