<div>
    <app-common-banner [heading]="'PACKAGES'"></app-common-banner>
  </div>
  <!-- <div>
    <app-parapack></app-parapack>
  </div> -->
  <div>
      <app-m-packet-tour></app-m-packet-tour>
  </div>
  <!-- <div>
    <app-pacakge-category></app-pacakge-category>
  </div> -->
  <!-- <div>
    <app-destination></app-destination>
  </div>  -->
  <div>
    <app-home-details></app-home-details>
  </div>
  