
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Regex } from 'src/app/helpers/regex-validate';

// @Component({
//   selector: 'app-foorgot-password',
//   templateUrl: './foorgot-password.component.html',
//   styleUrls: ['./foorgot-password.component.scss']
// })
// export class FoorgotPasswordComponent implements OnInit {

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private modalService: NgbModal) { }
  submitted = false;


  forgotform = new FormGroup({
    email: new FormControl('',Validators.compose([Validators.required,Validators.email,Validators.pattern(Regex.email)])),
  });
  get f() { return this.forgotform.controls; }
  ngOnInit(): void {
  }

  cross(){
    this.modalService.dismissAll();
    window.location.reload();

  }


  onSubmit() {
    this.submitted = true;
    console.log(this.forgotform.invalid)
    // stop here if form is invalid
    if (this.forgotform.invalid) {
        return;
    }
    if (this.forgotform.valid){
      console.log('dfsdfs')
    }
  }
}
