import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommingSoonComponent } from '../comming-soon/comming-soon.component';
import { MTourComponent } from '../packages/m-tour/m-tour.component';
import { sharedConstant } from './sharedConstant';
import { WrapperComponent } from './wrapper/wrapper.component';

const routes1: Routes = [
  {
    path:'',
    component:WrapperComponent,
    children :  [
      {
        path:'contact-us',
        loadChildren: () => import('../contact/contact.module').then(m => m.ContactModule)
      },
      {
        path:'comming-soon',
        component: CommingSoonComponent
      },
      
      {
        path:'booking',
        loadChildren: () => import('../booking/booking.module').then(m => m.BookingModule)
      },
      {
        path:'packages',
        component:MTourComponent
      },
      //package category
      {
        path: 'tour/:name',
        loadChildren: () => import('../packages/packages.module').then(m => m.PackagesModule)
      },
     
      //package type route
      {
        path:'tour-detail/:catName/:packageName',
        loadChildren: () => import('../tour-dtail/tour-dtail.module').then(m => m.TourDtailModule)
      },
      {
        path: sharedConstant.packageTypes[0].route,
        loadChildren: () => import('../tour-dtail/tour-dtail.module').then(m => m.TourDtailModule)
      },
      {
        path: sharedConstant.packageTypes[1].route,
        loadChildren: () => import('../tour-dtail/tour-dtail.module').then(m => m.TourDtailModule)
      },
      {
        path: sharedConstant.packageTypes[2].route,
        loadChildren: () => import('../tour-dtail/tour-dtail.module').then(m => m.TourDtailModule)
      },
      {
        path: sharedConstant.packageTypes[3].route,
        loadChildren: () => import('../tour-dtail/tour-dtail.module').then(m => m.TourDtailModule)
      },
      {
        path: sharedConstant.packageTypes[4].route,
        loadChildren: () => import('../tour-dtail/tour-dtail.module').then(m => m.TourDtailModule)
      },
      {
        path: sharedConstant.packageTypes[5].route,
        loadChildren: () => import('../tour-dtail/tour-dtail.module').then(m => m.TourDtailModule)
      },
      {
        path: sharedConstant.packageTypes[6].route,
        loadChildren: () => import('../tour-dtail/tour-dtail.module').then(m => m.TourDtailModule)
      },
      {
        path: sharedConstant.packageTypes[7].route,
        loadChildren: () => import('../tour-dtail/tour-dtail.module').then(m => m.TourDtailModule)
      },
      {
        path: sharedConstant.packageTypes[8].route,
        loadChildren: () => import('../tour-dtail/tour-dtail.module').then(m => m.TourDtailModule)
      },
      {
        path:'about-us',
        loadChildren: () => import('../about-us/about-us.module').then(m => m.AboutUsModule)
      },
      {
        path:'',
        loadChildren: () => import('../home/home.module').then(m => m.HomeModule)
      }
    ]
  
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes1)],
  exports: [RouterModule]
})
export class SharedRoutingModule { }
