export const sharedConstant = {

  packageCategory: [
    { img: '/../assets/images/tour15.jpg', head: 'Varanasi day Tour', route: 'varanasi-day-tour' },
    { img: '/../assets/images/tour5.jpg', head: 'Buddhist circuit', route: 'buddhist-circuit' },
    { img: '/../assets/images/tour14.jpg', head: 'Varanasi walk', route: 'varanasi-walk' },
    { img: '/../assets/images/tour12.jpg', head: 'Kashi-sangam', route: 'kashi-sangam' },
    { img: '/../assets/images/tour13.jpg', head: 'Pilgrimage tours.', route: 'pilgrimage-tours' },
    { img: '/../assets/images/tour8.jpg', head: 'Khasi Yatra', route: 'kashi-yatra' },
  ],

  packageTypes: [
    { route: '3-days-varanasi-tour', img: '/../assets/images/tour8.jpg', badge: '/../assets/images/Mask Group 143@2x.png', head: '3 Days - Varanasi Tour', star: '/../assets/images/star.svg', offstar: '/../assets/images/star (1).svg', btn: 'View Details', des: 'Travel to Varanasi on a flight of your own choice. Enjoy the comfort of living in a luxurious 5-star hotel close to Kashi Yatra' },
    { route: '2-days-nights-varanasi-tour', img: '/../assets/images/tour7.jpg', badge: '/../assets/images/Mask Group 143@2x.png', head: '2 Days-Nights - Varanasi Tour', star: '/../assets/images/star.svg', offstar: '/../assets/images/star (1).svg', btn: 'View Details', des: 'Travel to Varanasi on a flight of your own choice. Villa close to Varanasi Walk' },
    { route: '6-days-varanasi-tour', img: '/../assets/images/tour9.jpg', badge: '/../assets/images/Mask Group 143@2x.png', head: '6 Days - Varanasi Tour', star: '/../assets/images/star.svg', offstar: '/../assets/images/star (1).svg', btn: 'View Details', des: 'Travel to Varanasi on a flight of your own choice. Enjoy luxurious 4-star room close to Kashi Sangam' },
    { route: '5-days-varanasi-your', img: '/../assets/images/tour6.jpg', badge: '/../assets/images/Mask Group 143@2x.png', head: '5 Days - Varanasi Tour', star: '/../assets/images/star.svg', offstar: '/../assets/images/star (1).svg', btn: 'View Details', des: 'Travel to Varanasi on a flight of your own choice. Enjoy the comfort of living in a luxurious hotel close to Pilgrimage' },
    { route: '2-days-nights-varanasi-tour', img: '/../assets/images/tour10.jpg', badge: '/../assets/images/Mask Group 143@2x.png', head: '2 Days-Nights - Varanasi Tour', star: '/../assets/images/star.svg', offstar: '/../assets/images/star (1).svg', btn: 'View Details', des: 'Travel to Varanasi on a flight of your own choice. Enjoy the comfort of living in a luxurious 5-star hotel close to Buddhist Circuit' },
    { route: '3-days-varanasi-tour', img: '/../assets/images/tour5.jpg', badge: '/../assets/images/Mask Group 143@2x.png', head: '3 Days - Varanasi Tour', star: '/../assets/images/star.svg', offstar: '/../assets/images/star (1).svg', btn: 'View Details', des: 'Travel to Varanasi on a flight of your own choice. Enjoy the comfort of living in a 5-star Villa close to Ganga River' },
    { route: '8-days-varanasi-tour', img: '/../assets/images/tour2.jpg', badge: '/../assets/images/Mask Group 143@2x.png', head: '8 Days - Varanasi Tour', star: '/../assets/images/star.svg', offstar: '/../assets/images/star (1).svg', btn: 'View Details', des: 'Travel to Varanasi on a flight of your own choice. Enjoy the comfort in a luxurious 3-star hotel close to Kashi Day Tour' },
    { route: '9-days-varanasi-tour', img: '/../assets/images/tour3.jpg', badge: '/../assets/images/Mask Group 143@2x.png', head: '9 Days - Varanasi Tour', star: '/../assets/images/star.svg', offstar: '/../assets/images/star (1).svg', btn: 'View Details', des: 'Travel to Varanasi on a flight of your own choice. Enjoy the comfort of living in a luxurious 5-star room close to Kashi Ghat' },
    { route: '4-days-nights-varanasi-tour', img: '/../assets/images/tour11.jpg', badge: '/../assets/images/Mask Group 143@2x.png', head: '3 Days-Nights - Varanasi Tour', star: '/../assets/images/star.svg', offstar: '/../assets/images/star (1).svg', btn: 'View Details', des: 'Travel to Varanasi on a flight of your own choice. Enjoy the comfort of living in a luxurious 5-star Villa close to Temple' },
  ]

}