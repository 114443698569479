import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-banner',
  templateUrl: './common-banner.component.html',
  styleUrls: ['./common-banner.component.scss']
})
export class CommonBannerComponent implements OnInit {

  constructor() { }
  @Input() heading:string='heading';

  ngOnInit(): void {
  }

}
