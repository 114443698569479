// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-get-touch',
//   templateUrl: './get-touch.component.html',
//   styleUrls: ['./get-touch.component.scss']
// })
// export class GetTouchComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PackageService } from 'src/app/shared/service/package.service';

@Component({
  selector: 'app-get-touch',
  templateUrl: './get-touch.component.html',
  styleUrls: ['./get-touch.component.scss']
})
export class GetTouchComponent implements OnInit {
   email = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );
  
   
  loginForm = new FormGroup({
    name: new FormControl('',Validators.required),
    email: new FormControl('',Validators.required),
    contact: new FormControl('',[Validators.required,]),
    address: new FormControl('',Validators.required),
    check:new FormControl('',Validators.required),
  })
  constructor(
    private packageService: PackageService,
    private modelService: NgbActiveModal
    ) { }

  ngOnInit(): void {
  }

  checked: boolean = false;

  get formControls(){
    return this.loginForm.controls
  }
  show: boolean = false;


  onSubmit(){

    if(this.loginForm.invalid){
     this.show = true;
     // alert('valid')
    }

    let message = "Name-" + ' ' + this.loginForm.value.name + ' ' + "Mobile Number- " + ' ' + this.loginForm.value.contact + ' ' + "Month of Traveling- " + ' ' + this.loginForm.value.address
      const payload = {
        from: this.loginForm.value.email, 
        subject: "New Query from Get Quotes.",
        message: message
      }
      this.packageService.sendMail(payload).subscribe((res) => {
        console.log(this.loginForm.value, "Mail send!");
        this.modelService.close();
      }, error => {
        this.modelService.close();
        console.log(error);
        
      })

  }

}


