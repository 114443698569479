<section class="container-fluid">
    <div class="row footer-direc">
        <div class="col-md-9 bg-footer-left">
            <div class="d-flex justify-content-center logo-img">
                <img  class="mt-5" src="../../../assets/images/logo-2.svg" alt="">
            </div>
            <div class="d-flex justify-content-center footer-link mt-4">
                <a target="_blank" href="/"><span class="cursor-pointer">Home</span></a>
                <a target="_blank" href="/packages"><span class="cursor-pointer">Tour Packages</span></a>
                <!-- <span class="cursor-pointer" >Blogs</span> -->
                <!-- <span  class="cursor-pointer">Our Clients</span> -->
                <!-- <a target="_blank" href="/packages"><span  class="cursor-pointer">Our Policies</span></a>
                <span  class="cursor-pointer" (click)="openDialog(1)">Terms and Conditions</span> -->
                <a target="_blank" href="https://tourism.gov.in/uttar-pradesh"><span  class="cursor-pointer">COVID-19 Guideline</span></a>
            </div>
            <div class="d-flex justify-content-center footer-logo">
                <a href="https://www.facebook.com/magicalindiaOff" target="_blank"> <img  src="../../../assets/images/fb_c.svg" alt=""></a>
                <a href="https://www.linkedin.com/company/magical-india/"><img  src="../../../assets/images/in_c.svg" alt=""></a>
                <!-- <img  src="../../../assets/images/tw_c.svg" alt=""> -->
            </div>
        </div>
        <div class="col-md-3 bg-footer-right">
            <!-- <img src="../../../assets/images/footer-img.png" alt=""> -->
            <!-- <div class="d-flex justify-content-left footer-top-margin">
                <img class="" style="height: 20px;" src="../../../assets/images/call-landline.svg" alt="">
                <span >+91 945-539-3696</span>
            </div> -->
            <div class="d-flex justify-content-left footer-top-margin">
                <img class="px-3" src="../../../assets/images/whatsapp.svg" alt="">
                <span >+91 945-539-3696</span>
            </div>
            <div class="d-flex justify-content-left mt-3">
                <img class="px-3" src="../../../assets/images/gmail.svg" alt="">
                <span >contact@magicalindiajourney.com</span>
            </div>
            <div class="d-flex justify-content-left mt-3">
                <img class="px-3" style="height: 20px;" src="../../../assets/images/call-landline.svg" alt="">
                <span >054-2228-2141</span>
            </div>
        </div>
    </div>
</section>
<jjva-ngx-whatsapp-button [ngx_whatsapp_phone]="+919455393696" ngx_whatsapp_text="Hello, I am looking for tour packages!"></jjva-ngx-whatsapp-button>
