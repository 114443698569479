<div class="back-card p-3 bg-dark">
    <div class=" card p-3 bg-dark border-0">
        <h2 class="head text-white text-center">Get In Touch</h2>
        <form [formGroup]="loginForm">
            <div class="form-group">
                <label class="text-white  font-weight-bold">Name:</label>
                <input type="text" class="form-control" formControlName="name" pattern="[a-zA-Z][a-zA-Z ]+">
                <span class="error" *ngIf="formControls.name.invalid && formControls.name.touched">name is
                    invalid</span>
            </div>
            <div class="form-group">
                <label class="text-white  font-weight-bold">Email:</label>
                <input type="text" class="form-control" formControlName="email" email>
                <span class="error" *ngIf="formControls.email.invalid && formControls.email.touched">email is
                    invalid</span>
            </div>
            <div class="form-group">
                <label class="text-white  font-weight-bold">Contact no:</label>
                <input type="text" class="form-control" formControlName="contact" pattern="[0-9]*" minlength="10"
                    maxlength="10" required>
                <span class="error" *ngIf="formControls.contact.invalid && formControls.contact.touched">contact is
                    invalid</span>
            </div>
            <div class="form-group">
                <label class="text-white  font-weight-bold">Address</label>
                <textarea rows="4" cols="40" class="form-control" formControlName="address" pattern=""></textarea>
                <span class="error" *ngIf="formControls.address.invalid && formControls.address.touched">address is
                    invalid</span>
            </div>
            <!-- <div class="form-group">
           
            <input type="checkbox" [(ngModel)]="checked" formControlName="check">
            <span class="error" *ngIf="formControls.check.invalid && formControls.check.touched"> is required</span>
        </div> -->
            <div class="mt-2 mb-2">
                <button type="submit" class="free-quotes text-black border-0 font-weight-bold w-100"
                    (click)="onSubmit()">Submit</button>
            </div>
        </form>
    </div>
</div>