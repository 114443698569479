import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxWhastappButtonModule } from "ngx-whatsapp-button";
import { TermsAndConditionComponent } from 'src/app/all-popup/terms-and-condition/terms-and-condition.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    private modalservice: NgbModal

  ) { }

  ngOnInit(): void {
  }

  openDialog(openFor) {
    this.modalservice.open(TermsAndConditionComponent,
      {
        backdropClass: 'light-blue-backdrop',
        centered: true,
        size: "md",
        backdrop: true,
        animation: true,
        keyboard: false,
      });

  }

}
