import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GetTouchComponent } from 'src/app/all-popup/get-touch/get-touch.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input()
  logo:string='/../assets/images/logo.png';
  routerLinkActiveOptions: {
      exact: boolean;
  }
  constructor(
    private modalservice :NgbModal
  ) { }
  list=[
    {menu:'Home', link:'/'},
    {menu:'About Us', link:'/about-us'},
    {menu:'Packages', link:'/packages'},
    {menu:'Contact Us', link:'/contact-us'},
    {menu:'Get in Touch ', link:'/auth/login'},
  ]

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 20 ||
    document.documentElement.scrollTop > 20) {
      this.logo="/../assets/images/logo-2.svg";
      document.getElementById('header').classList.add('fixed-top');
    }
    else{
      document.getElementById('header').classList.remove('fixed-top');
      this.logo="/../assets/images/logo.png";
    }
  }

  GetTouch() {
    this.modalservice.open(GetTouchComponent, { backdropClass: 'light-blue-backdrop', centered: true });
  }

  // isLoggedIn = false;
  ngOnInit(): void {

    // if (localStorage.getItem('accessToken')) {
    //   this.isLoggedIn = true
    // }
  }

}
