import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { WrapperComponent } from './wrapper/wrapper.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BannerCardComponent } from './banner-card/banner-card.component';
import { BannerBlueComponent } from './banner-blue/banner-blue.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CommonBannerComponent } from './common-banner/common-banner.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxWhastappButtonModule } from 'ngx-whatsapp-button';
import { CommingSoonComponent } from '../comming-soon/comming-soon.component';

@NgModule({
  declarations: [
    WrapperComponent,
    HeaderComponent,
    FooterComponent,
    BannerCardComponent,
    BannerBlueComponent,
    CommonBannerComponent,
    CommingSoonComponent,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModule.forRoot(),
    NgxWhastappButtonModule
  ],
  exports:[
    BannerBlueComponent,
    BannerCardComponent,
    HeaderComponent,
    CommonBannerComponent,
    CommingSoonComponent
  ]
})
export class SharedModule { }
