import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { SharedModule } from './shared/shared.module';
import { ForgotPasswordComponent } from './all-popup/forgot-password/forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BookingComponent } from './all-popup/booking/booking.component';
import { GetTouchComponent } from './all-popup/get-touch/get-touch.component';
import { NgxWhastappButtonModule } from "ngx-whatsapp-button";
import { HttpClientModule } from '@angular/common/http';
import { TermsAndConditionComponent } from './all-popup/terms-and-condition/terms-and-condition.component';

@NgModule({
  declarations: [
    AppComponent,
    ForgotPasswordComponent,
    BookingComponent,
    GetTouchComponent,
    TermsAndConditionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    NgxWhastappButtonModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
