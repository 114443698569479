import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PackageDetailsJson, PackageList, PackageTypeJson } from '../model';

@Injectable({
  providedIn: 'root'
})
export class PackageService {

  packageTypeJson = '/assets/data/mji-category.json';
  packageDetailsJson = '/assets/data/new-data.json';
  packageList = '/assets/data/package-list.json';

  constructor(
    private http: HttpClient
  ) { }

  getPackageType(): Observable<PackageTypeJson[]> {
    return this.http.get<PackageTypeJson[]>(this.packageTypeJson);
  }

  getPackageDetails(): Observable<PackageDetailsJson[]> {
    return this.http.get<PackageDetailsJson[]>(this.packageDetailsJson);
  }

  getPackageList(): Observable<PackageList[]> {
    return this.http.get<PackageList[]>(this.packageList);
  }
  
  sendMail(payload) {
    // const apiUlr = `http://email.magicalindiajourney.com/mail.php?from=${payload.from}&subject=${payload.subject}&message=${payload.message}`
    const apiUlr = `https://email.magicalindiajourney.com/mail.php?from=${payload.from}&subject=${payload.subject}&message=${payload.message}`
    
    return this.http.get(apiUlr)
  }
}

