<!-- <div class="card text-center" *ngIf="step1">
    <form [formGroup]="bookingUpFormStep1" (ngSubmit)="onSubmitStep1(signUpFormStep1.value)">
        <input type="text" formControlName="fullName" oninput="this.value = this.value.replace(/[^a-z,A-Z, ]/g, '').replace(/(\..*)\./g, '$1');"   placeholder="First Name">
        <div class="error" *ngIf="submitted1 && f.fullName.errors">
            <div *ngIf="f.fullName.errors.required">
                <p>This is a required entry</p>
            </div>
        </div>
        <input type="text" formControlName="email" placeholder="Email">
        <div class="error" *ngIf="submitted1 && f.email.errors">
            <div *ngIf="f.email.errors.required">
                <p>This is a required entry</p>
            </div>
            <div *ngIf="f.email.errors.pattern">
                <p>Email filed only accepts valid domains</p>
            </div>
        </div>
        <input type="textarea" formControlName="description" placeholder="Description">
        <div class="error" *ngIf="submitted1 && f.description.errors">
            <div *ngIf="f.description.errors.required">
                <p>This is a required entry</p>
            </div>
        </div>
        <div class="mt-4"><button (click)="onSubmitStep1(bookingUpFormStep1.value)" class="button-auth w-100">Next 1</button></div>
    </form>
</div>
<div class="card text-center" *ngIf="step2">
    <form [formGroup]="bookingUpFormStep2" (ngSubmit)="onSubmitStep2(bookingUpFormStep2.value)">
        
        <input type="text" formControlName="cardName" oninput="this.value = this.value.replace(/[^a-z,A-Z, ]/g, '').replace(/(\..*)\./g, '$1');"   placeholder="Card Name">
        <div class="error" *ngIf="submitted1 && s.cardName.errors">
            <div *ngIf="s.cardName.errors.required">
                <p>This is a required entry</p>
            </div>
        </div>
        <input type="password" onKeyPress="if(this.value.length==6) return false;" formControlName="pin" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"   placeholder="PIN">
        <div class="error" *ngIf="submitted1 && s.pin.errors">
            <div *ngIf="s.pin.errors.required">
                <p>This is a required entry</p>
            </div>
        </div>
        <input type="text" onKeyPress="if(this.value.length==3) return false;" formControlName="cvv" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"   placeholder="CVV">
        <div class="error" *ngIf="submitted1 && s.cvv.errors">
            <div *ngIf="s.cvv.errors.required">
                <p>This is a required entry</p>
            </div>
        </div>
        <div class="mt-4"><button (click)="onSubmitStep2(bookingUpFormStep2.value)"  class="button-auth w-100">Next 2</button></div>
    </form>
</div>
<div class="card text-center" *ngIf="step3">
    <form [formGroup]="bookingUpFormStep3" (ngSubmit)="onSubmitStep3(bookingUpFormStep3.value)">
        <input type="text" formControlName="message"  placeholder="Message">

      
        <div class="mt-4"><button (click)="onSubmitStep3(bookingUpFormStep3.value)" class="button-auth w-100">Next 3</button></div>
    </form>
</div> -->
<div class="ron-card ">

    <div class="overlay-sh p-5">
        <h3 class="text-center font-weight-bold py-2 text-black w-100">
            {{formhead | titlecase}}
        </h3>
        <div class="card bg-transparent  py-3 text-center" *ngIf="step1">
            <form [formGroup]="bookingUpFormStep1">
                <div class="form-field my-3">
                    <label class="mb-0 text-left w-100 text-black" for="fullName">First Name</label>
                    <input mdbInput id="fullName" class="w-100 form-control" type="text" formControlName="fullName"
                        oninput="this.value = this.value.replace(/[^a-z,A-Z, ]/g, '').replace(/(\..*)\./g, '$1');"
                        placeholder="First Name">
                    <div class="error" *ngIf="submitted1 && f.fullName.errors">
                        <div *ngIf="f.fullName.errors.required">
                            <p>This is a required entry</p>
                        </div>
                    </div>
                </div>
                <div class="form-field my-3">
                    <label class="mb-0 text-left w-100 text-black" for="email">Email</label>
                    <input id="email" mdbInput class="w-100 form-control" type="text" formControlName="email" placeholder="Email">
                    <div class="error" *ngIf="submitted1 && f.email.errors">
                        <div *ngIf="f.email.errors.required">
                            <p>This is a required entry</p>
                        </div>
                        <div *ngIf="f.email.errors.pattern">
                            <p>Email filed only accepts valid domains</p>
                        </div>
                    </div>
                </div>
    
                <div class="form-field my-3">
                    <label class="mb-0 text-left w-100 text-black" for="contactNo">Contact Number</label>
                    <input id="contactNo" mdbInput class="w-100 form-control" type="text" formControlName="contactNo"
                        onKeyPress="if(this.value.length==10) return false;"
                        oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                        placeholder="Contact Number">
                    <div class="error" *ngIf="submitted1 && f.contactNo.errors">
                        <div *ngIf="f.contactNo.errors.required">
                            <p>This is a required entry</p>
                        </div>
                    </div>
                </div>
    
    
    
                <div class="form-field my-3">
                    <label class="mb-0 text-left w-100 text-black" for="country">Country</label>
    
                    <input id="country" mdbInput class="w-100 form-control" type="text" formControlName="country"
                        oninput="this.value = this.value.replace(/[^a-z,A-Z, ]/g, '').replace(/(\..*)\./g, '$1');"
                        placeholder="Country">
                    <div class="error" *ngIf="submitted1 && f.country.errors">
                        <div *ngIf="f.country.errors.required">
                            <p>This is a required entry</p>
                        </div>
                    </div>
                </div>
    
    
    
                <div class="mt-4">
                    <button (click)="onSubmitStep1(bookingUpFormStep1.value)"
                        class="view-all text-white border-0 font-weight-bold g-trans">Procced</button>
                </div>
    
    
    
            </form>
        </div>
        <div class="card bg-transparent  py-3 text-center" *ngIf="step2">
            <form [formGroup]="bookingUpFormStep2" (ngSubmit)="onSubmitStep2(bookingUpFormStep2.value)">
    
    
                <div class="form-field my-3">
                    <label class="mb-0 text-left w-100 text-black" for="numAdult">Number of Adult</label>
                    <input mdbInput class="w-100 form-control" type="number" formControlName="numAdult" id="numAdult"
                        oninput="this.value = this.value.replace(/[^0-9,0-9]/g, '').replace(/(\..*)\./g, '$1');"
                        placeholder="Number of Adult">
                    <div class="error" *ngIf="submitted1 && s.numAdult.errors">
                        <div *ngIf="s.numAdult.errors.required">
                            <p>This is a required entry</p>
                        </div>
                    </div>
                </div>
    
                <div class="form-field my-3">
                    <label class="mb-0 text-left w-100 text-black" for="numChild">Number of Children</label>
                    <input mdbInput class="w-100 form-control" type="number" formControlName="numChild" id="numChild"
                        oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                        placeholder="Number of Children">
                    <div class="error" *ngIf="submitted1 && s.numChild.errors">
                        <div *ngIf="s.numChild.errors.required">
                            <p>This is a required entry</p>
                        </div>
                    </div>
                </div>
    
                <div class="form-field  my-2">
                    <label class="mb-0 text-left w-100 text-black" for="hotelCat">hotel type</label>
                    <input mdbInput class="w-100 form-control" type="text" formControlName="hotelCat" id="hotelCat"
                        oninput="this.value = this.value.replace(/[^a-z,A-Z, ]/g, '').replace(/(\..*)\./g, '$1');"
                        placeholder="hotel type">
                    <div class="error" *ngIf="submitted1 && s.hotelCat.errors">
                        <div *ngIf="s.hotelCat.errors.required">
                            <p>This is a required entry</p>
                        </div>
                    </div>
                </div>
    
                <div class="form-field my-3">
                    <!-- <input mdbInput class="w-100 form-control" type="date" 
                        placeholder="dateArrival">
                    <div class="error" *ngIf="submitted1 && s.dateArrival.errors">
                        <div *ngIf="s.dateArrival.errors.required">
                            <p>This is a required entry</p>
                        </div>
                    </div> -->
    
                    <div class="input-group">
                        <label class="mb-0 text-left w-100 text-black" for="dateArrival">Contact Number</label>
                        <input class="form-control" placeholder="Date Of Arrival" formControlName="dateArrival" id="dateArrival"
                               name="dp" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                        </div>
                      </div>
    
    
                </div>
    
                <div class="form-field my-3">
                    <label class="mb-0 text-left w-100 text-black" for="information">Information</label>
                    <input mdbInput class="w-100 form-control" type="textarea" formControlName="information" id="information"
                    oninput="this.value = this.value.replace(/[^a-z,A-Z, ]/g, '').replace(/(\..*)\./g, '$1');"
                    placeholder="Information">
                <div class="error" *ngIf="submitted1 && s.information.errors">
                    <div *ngIf="s.information.errors.required">
                        <p>This is a required entry</p>
                    </div>
                </div>
            </div>
    
    
    
    
    
    
    
    
                <!-- <div class="form-field my-3"><input mdbInput class="w-100 form-control" type="password"
                        onKeyPress="if(this.value.length==6) return false;" formControlName="pin"
                        oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                        placeholder="PIN">
                    <div class="error" *ngIf="submitted1 && s.pin.errors">
                        <div *ngIf="s.pin.errors.required">
                            <p>This is a required entry</p>
                        </div>
                    </div>
                </div>
                <div class="form-field my-3"><input mdbInput class="w-100 form-control" type="text"
                        onKeyPress="if(this.value.length==3) return false;" formControlName="cvv"
                        oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                        placeholder="CVV">
                    <div class="error" *ngIf="submitted1 && s.cvv.errors">
                        <div *ngIf="s.cvv.errors.required">
                            <p>This is a required entry</p>
                        </div>
                    </div> -->
                    <div class="mt-4"><button (click)="onSubmitStep2(bookingUpFormStep2.value)"
                            class="view-all text-white border-0 font-weight-bold g-trans">Book</button></div>
                <!-- </div> -->
            </form>
        </div>
        <div class="card bg-transparent  py-3 text-center" *ngIf="step3">
            <div class="min-card py-3">
                <img src="/../assets/images/check-mark@2x.png" width="105px" height="105px">
                <h2 class="font-weight-medium">Your Booking Has been Successfully Completed</h2>
                <p>Kashi Yatra : The north part of India features several of extremely well-known religious spots. Banaras,
                    Prayagraj, Gaya, Ayodhya and Naimisharanya Tour will take you through the favored religious locations.
                </p>
                <button class="view-all text-white border-0 font-weight-bold g-trans" routerLink='/' (click)="close()">Back
                    To Home Page</button>
            </div>
            <!-- <form [formGroup]="bookingUpFormStep3" (ngSubmit)="onSubmitStep3(bookingUpFormStep3.value)">
        
        <div class="form-field my-3"><input mdbInput class="w-100 form-control" type="text" formControlName="message" placeholder="Message">
    
    
            <div class="mt-4"><button (click)="onSubmitStep3(bookingUpFormStep3.value)"
                    class="button-auth w-100">Next 3</button></div></div>
            </form> -->
        </div>
    </div>
</div>