import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-details',
  templateUrl: './home-details.component.html',
  styleUrls: ['./home-details.component.scss']
})
export class HomeDetailsComponent implements OnInit {
  data: any[]= [
    {
      img: '/assets/images/XMLID_170_.svg',
      title: 'aaaa',
      details: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.  fugit laudantium'
    },
    {
      img: '/assets/images/place.svg',
      title: 'aaa',
      details: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.  fugit laudantium'
    },
    {
      img: '/assets/images/XMLID_170_.svg',
      title: 'aaaa',
      details: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.  fugit laudantium'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
